import React from 'react'
import { Redirect } from '@reach/router'

function RedirectWithReplace ({ location, pageContext }) {
  const currentPathname = location.pathname
  const { from, to } = pageContext
  console.log('redirect', from ,to, currentPathname, currentPathname.replace(from, to))
  return (
    <Redirect to={currentPathname.replace(from, to)} />
  )
}

export default RedirectWithReplace
